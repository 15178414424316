import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`Automate calling external APIs and handling response data. Supports any API with JSON responses. Uses Fetch for
performing API requests, normalizr for handling response data and redux for storing data.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p><inlineCode parentName="p">{`npm install react-api-data`}</inlineCode></p>
    <p>{`or`}</p>
    <p><inlineCode parentName="p">{`yarn add react-api-data`}</inlineCode></p>
    <p>{`Make sure `}<em parentName="p">{`fetch`}</em>{` is available globally, polyfill it if needed to support older environments.`}</p>
    <h2 {...{
      "id": "install-dependencies"
    }}>{`Install dependencies`}</h2>
    <p>{`React-api-data requires the installation of the peer-dependencies react-redux, redux-thunk and normalizr.
These can be installed with the following command:`}</p>
    <p><inlineCode parentName="p">{`npm install redux react-redux redux-thunk normalizr`}</inlineCode></p>
    <p>{`or`}</p>
    <p><inlineCode parentName="p">{`yarn add redux react-redux redux-thunk normalizr`}</inlineCode></p>
    <h2 {...{
      "id": "quick-usage"
    }}>{`Quick usage`}</h2>
    <h3 {...{
      "id": "config"
    }}>{`Config`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { schema } from 'normalizr';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { configureApiData, reducer } from 'react-api-data';
import thunk from 'redux-thunk';

// optionally define normalizr response schemas

const authorSchema = new schema.Entity('Author');
const articleSchema = new schema.Entity('Article', {
    author: authorSchema
});

// define api endpoints

const endpointConfig = {
    getArticle: {
        url: 'http://www.mocky.io/v2/5a0c203e320000772de9664c?:articleId/:userId',
        method: 'GET',
        responseSchema: articleSchema
    },
    saveArticle: {
        url: 'http://www.mocky.io/v2/5a0c203e320000772de9664c?:articleId',
        method: 'POST',
        afterSuccess: ({ dispatch, request, getState }) => {
            // After successful post, invalidate the cache of the getArticle call, so it gets re-triggered.
            dispatch(invalidateApiDataRequest('getArticle', {articleId: request.params.articleId, userId: getState().userId})); 
        }
    }
};

// Configure store and dispatch config before you render components

const store = createStore(combineReducers({apiData: reducer}), applyMiddleware(thunk));
store.dispatch(configureApiData({}, endpointConfig));
`}</code></pre>
    <h3 {...{
      "id": "bind-api-data-to-your-component"
    }}>{`Bind API data to your component`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import { useApiData } from 'react-api-data';

const Article = (props) => {
    const article = useApiData('getArticle', { id: props.articleId });
    return (
        <>
            {article.request.networkStatus === 'success' && 
                <div>
                    <h1>{article.data.title}</h1>
                    <p>{article.data.body}</p>
                </div>
            }
        </>
    );
}

`}</code></pre>
    <h3 {...{
      "id": "post-data-from-you-component"
    }}>{`Post data from you component`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { useState } from 'react';
import { useApiData } from 'react-api-data';

const PostComment = props => {
    const [comment, setComment] = useState('');
    const postComment = useApiData('postComment');
    const { networkStatus } = postComment.request;
    const onSubmit = () => {
        postComment.perform({ id: props.articleId }, { comment });
    };
    return (
        <>
            {networkStatus === 'ready' && (
                <div>
                    <input
                        onChange={event => setComment(event.target.value)}
                        placeholder="Add a comment..."
                    />
                    <button onClick={onSubmit}>Submit</button>
                </div>
            )}
            {networkStatus === 'loading' && <div>Submitting...</div>}
            {networkStatus === 'failed' && (
                <div>
                    Something went wrong.
                    <button onClick={onSubmit}>Try again</button>
                </div>
            )}
            {networkStatus === 'success' && <div>Submitted!</div>}
        </>
    );
};
`}</code></pre>
    <h1 {...{
      "id": "the-gist"
    }}>{`The gist`}</h1>
    <p>{`Calling external API endpoints and storing response data in your `}<a parentName="p" {...{
        "href": "https://redux.js.org"
      }}>{`redux`}</a>{` state can create
bloat in your code when you have multiple endpoints, especially in `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Create,_read,_update_and_delete"
      }}>{`CRUD`}</a>{`
applications. This package is the result of eliminating repetitive code around API calls and centralizing the concerns of
fetching and storing API data into one single package. It provides an easy to use interface that aims to minimize the
amount of code needed to use data from external APIs, while maintaining maximum flexibility to support any non-standard
API. The idea is that you can just bind data from a given API endpoint to your component, react-api-data takes care of
fetching the data if needed, and binding the data to your component.`}</p>
    <h1 {...{
      "id": "examples"
    }}>{`Examples`}</h1>
    <h2 {...{
      "id": "caching-api-responses"
    }}>{`Caching API responses`}</h2>
    <p>{`Responses from successful API calls will be kept in memory so the same call won't be re-triggered a second time. This is especially useful when using `}<em parentName="p">{`withApiData`}</em>{` for the same endpoint on multiple components.
You can set a `}<em parentName="p">{`cacheDuration`}</em>{` to specify how long the response is considered valid, or to disable the caching entirely.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export default {
    getArticle: {
        url: 'http://www.mocky.io/v2/5a0c203e320000772de9664c?:articleId/:userId',
        method: 'GET',
        cacheDuration: 60000, // 1 minute
    },
    getComments: {
        url: 'http://www.mocky.io/v2/5a0c203e320000772de9664c?:articleId',
        method: 'GET',
        cacheDuration: 0, // no caching, use with caution. Preferably set to a low value to prevent multiple simultaneous calls.
    },
    getPosts: {
        url: 'http://www.mocky.io/v2/5a0c203e320000772de9664c?:articleId',
        method: 'GET'
        // Infinite caching
    },
}
`}</code></pre>
    <h2 {...{
      "id": "manually-clearing-cache-from-your-component"
    }}>{`Manually clearing cache from your component`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useApiData } from 'react-api-data';

const Articles = props => {
    const getArticles = useApiData('getArticles');
    return (
        <>
            {/* ... */}
            <button onClick={getArticles.invalidateCache}>Refresh</button>
        </>
    );
}

`}</code></pre>
    <h2 {...{
      "id": "manually-clearing-cache-using-useactions"
    }}>{`Manually clearing cache using useActions`}</h2>
    <p>{`Using the useActions api to invalidate the cache of a specific endpoint.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useActions} from 'react-api-data';

const Articles = props => {
    const actions = useActions();
    return (
        <>
            {/* ... */}
            <button onClick={() => actions.invalidateCache('getArticle', {id: '1234'})}>Refresh</button>
        </>
    );
}

`}</code></pre>
    <h2 {...{
      "id": "removing-api-data-from-the-store"
    }}>{`Removing api data from the store`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { useActions} from 'react-api-data';

const LogoutComponent = props => {
    const actions = useActions();
    return (
        <>
            {/* ... */}
            <button onClick={() => actions.purgeAll()}>Logout</button>
        </>
    );
}
`}</code></pre>
    <h2 {...{
      "id": "including-cookies-in-your-request"
    }}>{`Including Cookies in your Request`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export const globalConfig = {
    setRequestProperties: (defaultProperties) => ({
        ...defaultProperties,
        credentials: 'include',
    })
};
`}</code></pre>
    <h2 {...{
      "id": "uploading-a-file"
    }}>{`Uploading a file`}</h2>
    <p><a parentName="p" {...{
        "href": "./example.file-upload.md"
      }}>{`See file Upload examples`}</a></p>
    <h2 {...{
      "id": "make-multiple-requests-to-the-same-endpoint-at-once"
    }}>{`Make multiple requests to the same endpoint at once`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const connectApiData = withApiData({
    items: 'getItemsInList'
}, (ownProps, state) => ({
    items: [{listId: 1}, {listId: 2}, {listId: 3}]
}));

const ItemsList = (props) => {
    if (props.items.every(item => item.request.networkStatus === 'success')) {
        return (
            <ul>
                {props.items.map(item => (<li>{item.data.title}</li>))}
            </ul>
        );
    }
    return <p>Loading...</p>;
}

export default connectApiData(ItemsList);
`}</code></pre>
    <h2 {...{
      "id": "configure-with-redux-persist"
    }}>{`Configure with Redux-persist:`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`    
    // Use the callback of redux-persist to dispatch the afterRehydrate function.
    // This will make sure all loading states are properly reset.
    const persistor = persistStore(store, {}, () => store.dispatch(afterRehydrate()));
    store.dispatch(configureApiData({}, endpointConfig));
    return {
        store,
        persistor,
    };
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      